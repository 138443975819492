export default ({ $cookies }, inject) => {
	const flagConfig = {
		// store_stan_payment_plans_visible: { probability: 0.5 },
		store_product_coming_soon_experiment: { probability: 0.5 },
		payment_orchestration_phase_1: { probability: 0.01 },
		strikethru_price_near_checkout: { probability: 0.5 },
	}

	// Intermediate independent state without considering for dependencies [DO NOT USE OUTSIDE OF THIS SCRIPT]
	for (const [key, _] of Object.entries(flagConfig)) {
		if (flagConfig[key] === undefined) {
			console.error('Feature flag is not defined: ', key)
			flagConfig[key].state = false
		} else {
			flagConfig[key].state = Math.random() <= flagConfig[key].probability
		}
	}

	// Pull flags data from cookie to persist
	const cookieFlagData = $cookies.get('session_flags') || {}
	for (const [key, _] of Object.entries(flagConfig)) {
		if (cookieFlagData && cookieFlagData[key]) {
			flagConfig[key].state = !!cookieFlagData[key].experiment_variant
		}
	}

	const checkFlag = name => {
		const flagData = flagConfig[name]
		const deps = flagData.deps || []

		if ($cookies.get(name)) {
			return $cookies.get(name) === true
		}

		// Turn off flag if it doesn't have dependencies
		for (var i = 0; i < deps.length; i++) {
			if (flagConfig[deps[i]] === undefined) {
				console.error('Unknown flag dependency', deps[i])
				return false
			}
			if (flagConfig[deps[i]].state === false) {
				return false
			}
		}

		try {
			if (!flagData) {
				console.error('Unknown feature flag, ', name)
				return false
			}
			return flagData.state
		} catch (error) {
			console.error(error)
			return false
		}
	}

	const computedFlags = {}
	const computedFlagData = {}

	for (const [key, _] of Object.entries(flagConfig)) {
		const value = checkFlag(key)
		computedFlags[key] = value
		computedFlagData[key] = {
			experiment_variant: value ? 1 : 0,
		}
	}

	$cookies.set('session_flags', computedFlagData, { maxAge: 60 * 10, domain: 'stanwith.me' })
	$cookies.set('session_flags', computedFlagData, { maxAge: 60 * 10, domain: 'stan.store' })
	$cookies.set('session_flags', computedFlagData, { maxAge: 60 * 10 })

	const isFlag_ = name => {
		if (computedFlags[name] === undefined) {
			console.error('Feature flag is not defined: ', name)
			return false
		}
		return computedFlags[name]
	}

	const getFlags = () => computedFlagData
	const isFlag = isFlag_

	inject('getFlags', getFlags)
	inject('isFlag', isFlag_)
}
