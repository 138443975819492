<template>
	<div>
		<Nuxt />
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		computed: {
			user() {
				return this.$store.getters.getUser
			},
		},
		mounted() {
			window.onload = () => {
				if (window.clarity && this.user) {
					window.clarity('identify', this.$cookies.get('cookie_uuid'), this.$cookies.get('session_uuid'), 'nuxt-store', this.user.username)
				}
			}
		},
		watch: {
			user: {
				deep: true,
				immediate: true,
				handler(user) {
					if (user && user.data?.analytics?.facebook?.token && this.$fb) {
						setTimeout(() => {
							this.$fb.setPixelId(user.data.analytics.facebook.token)
							this.$fb.enable()
						}, 100)
					}
					if (user && user.data?.analytics?.tiktok?.token && this.$tt) {
						setTimeout(() => {
							this.$tt.setPixelId(user.data.analytics.tiktok.token)
							this.$tt.enable()
						}, 100)
					}

					// Load users google analytics token alongside our token
					if (user && user.data?.analytics?.google?.token && process.env.NUXT_ENV_APP_ENV_NAME == 'production') {
						setTimeout(async () => {
							const vueGtag = await import('vue-gtag')
							const gToken = user.data.analytics.google.token
							vueGtag.setOptions({
								config: { id: 'G-RF9HN9BW1D' },
								includes: [{ id: gToken }],
								appName: 'Stan Store',
								disableAutoPageTrack: false,
								params: {
									send_page_view: false,
								},
								pageTrackerScreenviewEnabled: true,
							})
							vueGtag.bootstrap().then(this.$gtag)
						}, 100)
					}
				},
			},
		},
	}
</script>
<style lang="scss">
	@import '~/assets/app.scss';
</style>
